import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'pqCleanText' })
export class CleanTextPipe implements PipeTransform {

  public transform (text: string, removeHighlight: boolean = false, removeHTML: boolean = false,textread?:boolean): string {
   
    let s = text ? text : '';
    if(textread){
      return s;
    }    
    s = this.removeBodyTag(s);
    s = this.removeStyleTag(s);
    s = this.updateHighlighting(s, removeHighlight);
    if (removeHTML) {
      // need to use DOM methods to sanitize text to preserve entities during HTML->text conversion
      s = this.getTextOnly(s);
    }
    return s.trim();
  }

  public getTextOnly (val: string): string {
    val = this.removeTags(val, []);
    const tmpEle = document.createElement('div');
    tmpEle.innerHTML = val;
    return tmpEle.textContent || tmpEle.innerText || '';
  }

  public removeTags (val: string, whitelist: string[]): string {
    const tagsplits = val.split('<');
    if (tagsplits.length === 1) {
      return val;
    }

    for (let x = 0; x < tagsplits.length; x++) {
      const tagIdx = this.startsWithAnyInArray(tagsplits[x], whitelist);
      if (tagIdx > -1) {
        tagsplits[x] = '<'
         + tagsplits[x].substring(0, whitelist[tagIdx].length + 1).toLowerCase()
         + tagsplits[x].substring(whitelist[tagIdx].length + 1);
        continue;
      }
      const idx = tagsplits[x].indexOf('>');
      if (idx > -1) {
        tagsplits[x] = tagsplits[x].substr(idx + 1);
      }
    }
    // console.log(tagsplits);
    return tagsplits.join('').trim();
  }

  private updateHighlighting (val: string, removeHighlights: boolean) {
    const mkupTagRegex = /\[\[mkup\]\]/g;
    let mkupTagCount = 1;
    return val.replace(mkupTagRegex, (match, i, original) => {
       mkupTagCount++;
       if (removeHighlights) {
         return '';
       } else {
         return (mkupTagCount % 2) === 0 ? '<b>' : '</b>';
       }
    });
  }

  private startsWithAnyInArray (val: string, whitelist: Array<string>):number {
    for(let y = 0; y < whitelist.length; y++) {
      if (val.substring(0, whitelist[y].length + 1).toLowerCase() === whitelist[y] + '>') {
        return y;
      }
      if (val.substring(0, whitelist[y].length + 1).toLowerCase() === whitelist[y] + ' ') {
        return y;
      }
      // handle closing tags
      if (val.substring(0, whitelist[y].length + 2).toLowerCase() === ('/' + whitelist[y]) + '>') {
        return y;
      }
    }
    return -1;
  }

  private removeBodyTag (val: string): string {
    const bodyRegex = /\<body[^>]*\>([^]*)\<\/body/m;
    // replace body tag
    // find text within body tag
    const bodyTextArray = /<body/.test(val) ? val.match(bodyRegex) : [val];
    // if there is text within body tag, use it otherwise use default string
    return bodyTextArray && bodyTextArray.length === 2 ? bodyTextArray[1] : val;
  }

  private removeStyleTag (val: string): string {
    const styleRegEx = /\<style[^>]*\>([^]*)\<\/style>/m;
    return val.replace(styleRegEx, '');
  }


}
